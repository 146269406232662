import LocomotiveScroll from 'locomotive-scroll';
import happens from 'happens';
import gsap from 'gsap';

class Scroller {
  public scroller: any;
  private element: HTMLElement;
  private resizeObserver: any;
  private readyPromise;
  public on: any;
  public off: any;
  public emit: any;

  constructor() {
    this.onScroll = this.onScroll.bind(this);
    happens(this);
  }

  public init(el): void {
    this.element = el;
    this.scroller = new LocomotiveScroll({
      el: this.element,
      smooth: true,
      scrollFromAnywhere: true,
      initPosition:{ x: 0, y: 0 },
      smartphone: {
        initPosition:{ x: 0, y: 0 },
        scrollFromAnywhere: true,
        smooth: true,
      },
      tablet: {
        initPosition:{ x: 0, y: 0 },
        scrollFromAnywhere: true,
        smooth: true,
      }
    })
    this.scroller.on('scroll', this.onScroll);
    this.resizeObserver = new (window as any).ResizeObserver(() => {
      // this.scroller.scrollTo('top')
      this.scroller.update();
    });
    this.resizeObserver.observe(this.element);
    if (this.readyPromise) {
      this.readyPromise();
      this.readyPromise = undefined;
    }
  }

  private onScroll(scroll) {
    this.emit('scroll', scroll);
  }

  public setTo(scrollY) {
    this.scroller.scrollTo(scrollY, {duration: 0, disableLerp: true});
  }

  gotoBottom() {
    this.emit('contact:start');
    setTimeout(() => {
      this.emit('contact:end');
    }, 3000);
    if (this.scroller) {
      this.scroller.scrollTo('bottom', {duration: 500})
    }
  }

  public ready() {
    return new Promise((resolve) => {
      this.readyPromise = resolve;
      if (this.scroller) {
        this.readyPromise();
        this.readyPromise = undefined;
      }
    })
  }

  destroy() {
    this.scroller.off('scroll', this.onScroll);
    this.readyPromise = undefined;
    this.resizeObserver.unobserve(this.element);
    this.resizeObserver.disconnect();
    this.scroller.destroy();
  }

  get scroll() {
    return this.scroller;
  }
}

export default new Scroller;
