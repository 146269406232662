import React, { forwardRef, useRef, useImperativeHandle, useEffect, useState, useContext } from "react"
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import { TypeParagraphSizes } from '../../common/typography/TypeParagraph';
import { Container, NavItem, ToggleView, ContactLink } from './styled';
import { onBreakpointChange } from 'src/util/respondTo';
import { Breakpoint } from '../../../data/styles/Breakpoint';
import { AppContext } from '../../Layout/Layout';
import MobileMenu from 'src/components/molecules/MobileMenu';
import scroller from '../../../util/scroller';

export interface MenuProps { className?: string, location: any };

const Menu = forwardRef((props: MenuProps, ref) => {

  const [ isMobile, setIsMobile ] = useState(false);
  const [ isMenuOpen, setIsMenuOpen ] = useState(false);
  const { t } = useTranslation();
  const [ menuLabel, setMenuLabel ] = useState('Menu');
  let removeOnBreakpoint;
  let context = useContext(AppContext);

  useEffect(() => {
    removeOnBreakpoint = onBreakpointChange((breakpoint: number) => {
      if (breakpoint <= Breakpoint.M) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });
    return () => {
      removeOnBreakpoint();
    }
  }, [])

  const clickContact = (e) => {
    e.preventDefault();
    scroller.gotoBottom();
  }

  useEffect(() => {
    if (isMenuOpen) {
      setMenuLabel('Close');
    } else {
      setMenuLabel('Menu');
    }
  }, [isMenuOpen])

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    context.setMenuOpen(!context.isMenuOpen);
  }

  return (
    <Container className={props.className}>
      {isMobile &&
        <NavItem onClick={toggleMenu} as={'button'} size={TypeParagraphSizes.M}>{ menuLabel }</NavItem>
      }
      {!isMobile &&
        <>
          <Link to='/about'>
            <NavItem active={/about/.test(props.location.pathname)} size={TypeParagraphSizes.M}>{ t('studio') }</NavItem>
          </Link>
          <ContactLink onClick={clickContact}>
            <NavItem size={TypeParagraphSizes.M}>{ t('contact') }</NavItem>
          </ContactLink>
          <Link to='/archive'>
            <NavItem  active={/archive/.test(props.location.pathname)} size={TypeParagraphSizes.M}>{ t('archive') }</NavItem>
          </Link>
        </>
      }
    </Container>
  )
})

export default Menu;
