import React, { forwardRef, useRef, useImperativeHandle } from "react"
import { useTranslation } from 'react-i18next';
import { TypeParagraphSizes } from '../../common/typography/TypeParagraph';
import { Container, Label } from './styled';

export interface FilterProps { className?: string };

const Filter = forwardRef((props: FilterProps, ref) => {

  const { t } = useTranslation();

  return (
    <Container className={props.className}>
      <Label size={TypeParagraphSizes.M}>{ t('allProjects') }</Label>
    </Container>
  )
})

export default Filter;
