import gsap from 'gsap';
import { Mesh, MeshBasicMaterial, Object3D } from 'three';
import IAsset from '../../interface/IAsset';
import random from '../../util/random';
import Image3D from './Image3D';
import planeGeometry from './geometry/plane';

export default class IndexCover {
  public mesh: Object3D;
  private imageData: IAsset;
  public image: Image3D;
  private viewport: any;
  private transitioned: boolean = false;
  public hitarea: any;
  private imageX: number;

  constructor(imageData: IAsset, app: any) {
    this.imageData = imageData;
    this.viewport = app.viewport;

    this.mesh = new Object3D();
    this.image = new Image3D(app.theme.urls.assetsBase() + this.imageData.formats.medium.url, this.viewport, false, true);
    this.mesh.add(this.image.mesh);
    this.image.z = -0.1
  }

  preload(cb: any) {
    this.image.preload(() => {
      cb();
    });
    return this.animationLoaded();
  }

  public overAnimation(x) {
    gsap.killTweensOf(this.image);
    gsap.to(this.image, {
      duration: 0.7,
      x: x * -0.25,
      hoverTransition: 1,
      ease: 'expo.out',
      delay:0,
    })
  }

  public outAnimation(delay) {
    gsap.killTweensOf(this.image);
    gsap.to(this.image, { duration:0.5, x:0, hoverTransition: 0, ease: 'expo.out' } )
  }

  public animationLoaded() {
    const tl = gsap.timeline();
    const randX = random() * 4;
    const randY = random() * 4;
    tl.fromTo(this.image, {x: 0,y: 0}, {
      x: randX,
      y: randY,
      alpha: 1,
      ease: 'power3.out',
      duration: 0.75,
    }, 0);
    return tl;
  }

  public transitionIn() {
    const tl = gsap.timeline();
    tl.to(this.image, { x: 0, y: -0.5 * this.image.mesh.scale.y, ease: 'power3.out', duration: 1, onComplete: () => {
      this.transitioned = true;
    } });
    return tl;
  }

  public update() {
    this.image.uTime += 0.1;
    this.image.update();
  }

  resize(viewport) {
    this.viewport = viewport;
    this.image.resize(viewport);
    if (this.transitioned) {
      this.image.y = -0.5 * this.image.mesh.scale.y
    }
  }

  dispose() {
    this.transitioned = false;
    this.image.x = 0;
    this.image.y = 0;
    this.image.alpha = 0;
    this.mesh.position.x = 0;
    this.mesh.position.y = 0;
  }

  get width() {
    return this.image.width;
  }

  get height() {
    return this.image.height
  }
}
