import gsap from 'gsap';
import happens from 'happens';
import { Object3D, PerspectiveCamera, Scene, WebGLRenderer } from 'three';
import { Breakpoint } from '../../data/styles/Breakpoint';
import scroller from 'src/util/scroller';
import WebGLFooter from './WebGLFooter';

export default class WebGLIndexPage {
  private app: any;
  private scene: Scene;
  private camera: PerspectiveCamera;
  private renderer: WebGLRenderer;
  private footer: WebGLFooter;
  private element: any;
  private mesh: Object3D;
  private layout: {
    horizontalMargin: number
    height: number
  } = {
    horizontalMargin: 15,
    height: 0
  }
  public on: Function;
  public emit: Function;
  private active: boolean = false;

  constructor(app: any) {
    this.app = app;
    this.mesh = new Object3D();
    this.scene = this.app.scene;
    this.camera = this.app.camera;
    this.renderer = this.app.renderer;
    happens(this);
  }

  public initialize(element) {
    this.element = element;
    this.scene.add(this.mesh);
    this.addFooter()
    this.addEvents();
    console.log('initialize')
  }

  private addFooter() {
    this.footer = new WebGLFooter(this.app);
    this.footer.mesh.position.y = -this.app.viewport.height * 2;
    this.mesh.add(this.footer.mesh);
  }

  public update() {
    if (!this.active) return;
    if (this.footer) {
      this.footer.update();
    }
  }

  private async addEvents() {
    await scroller.ready();
    scroller.scroll.on('scroll', (scroll) => {
      const percent = scroll.scroll.y / scroll.limit.y;
      this.mesh.position.y = -percent * this.layout.height
    })
    this.resize();
  }

  public resize(): void {
    if (!this.active) return;
    if (this.footer) {
      const footerY = (this.element.offsetHeight / window.innerHeight) * this.app.viewport.height;
      this.layout.height = -footerY * 2.5;
      this.footer.mesh.position.y = -footerY * 2.5;
      this.footer.resize();
    }
  }

  public onBreakpointChange(breakpoint: number): void {
    if (breakpoint < Breakpoint.M) {
      this.layout.horizontalMargin = .20 * this.app.viewport.width;
    } else {
      this.layout.horizontalMargin = .20 * this.app.viewport.width;
    }
    this.resize();
  }

  public transitionIn(): void {
    this.active = true;
    this.mesh.visible = true;
  }

  public transitionOut(): void {
    this.active = false;
    this.mesh.visible = false;
  }
}
