import styled, { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import 'src/css/locomotive.css'
import { Breakpoint } from '../../data/styles/Breakpoint';
import { minWidth } from '../../util/style/mixins/respondTo';
import Header from 'src/components/organisms/Header';

export const GlobalStyle = createGlobalStyle`
  ${reset};

  button:focus {outline:0;}

  div {
    box-sizing: border-box;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  html {
    font-size: calc(100vw / 375 * 10);
    ${minWidth(Breakpoint.M)} {
      font-size: calc(100vw / 1920 * 10);
    }
  }

  body {
    width: 100%;
    background: ${props => props.theme.color.white};
  }

  html, body {
    width: 100%;
    height: 100%;
  }
`;

export const HeaderContainer = styled(Header)``
