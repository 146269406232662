import { Breakpoint } from '../data/styles/Breakpoint';
import chuckArray from './chuckArray';
const breakpoints =  chuckArray(Object.values(Breakpoint), 2)[1];
export const getCurrentMinWidth = () => {
  let breakpoint = 0;
  breakpoints.map((b: number) => {
    if (window.innerWidth > b) {
      breakpoint = b;
    }
  })
  return breakpoint;
}

export const onBreakpointChange = (callback: Function) => {
  let current = -1;
  const onResize = () => {
    if (current !== getCurrentMinWidth()) {
      current = getCurrentMinWidth();
      callback(current)
    }
  }
  onResize();
  window.addEventListener('resize', onResize);
  return () => {
    window.removeEventListener('resize', onResize);
  }
}
