import React, { forwardRef, useRef, useImperativeHandle, useEffect, useContext, useState } from "react"
import { onBreakpointChange } from '../../../util/respondTo';
import { TypeHeadingSizes } from '../../common/typography/TypeHeading';
import { ThemeContext } from '../../Layout/Layout';
import webglApp from '../../webgl/WebGLApp';
import { Container, ProjectTitle } from './styled';

export interface WebGLApplicationProps { className?: string, theme?: any };

const WebGLApplication = forwardRef((props: WebGLApplicationProps, ref) => {
  const el = useRef<HTMLElement>();
  let rAF;
  let removeOnBreakpointChange;
  const theme = useContext(ThemeContext);
  let [isTitleVisible, setIsTitleVisible] = useState(false)
  let [projectTitle, setProjectTitle] = useState('Project')

  useEffect(() => {
   return () => {
    window.cancelAnimationFrame(rAF)
    removeOnBreakpointChange();
    window.removeEventListener('resize', onResize);
    webglApp.off('project:over', showTitle);
    webglApp.off('project:out', hideTitle);
    webglApp.destroy();
   }
  }, [])

  const update = () => {
    rAF = window.requestAnimationFrame( update );
    webglApp.update();
  }

  const onResize = () => {
    webglApp.resize();
  }

  const addListeners = () => {
    window.addEventListener('resize', onResize);
    onResize();
    removeOnBreakpointChange = onBreakpointChange(breakpoint => {
      webglApp.onBreakpointChange(breakpoint)
    })
    webglApp.on('project:over', showTitle);
    webglApp.on('project:out', hideTitle);
  }

  const showTitle = (project) => {
    setProjectTitle(project.title);
    setIsTitleVisible(true);
  }

  const hideTitle = () => {
    setIsTitleVisible(false);
  }

  useEffect(() => {
    webglApp.initialize(el, theme)
    addListeners();

    update();
    return dispose;
  }, [])

  const dispose = () => {
    removeOnBreakpointChange();
  }

  return (
    <Container ref={el} className={props.className}>
      <ProjectTitle
        visible={isTitleVisible}
        size={TypeHeadingSizes.H1}
      >
        {projectTitle}
      </ProjectTitle>
    </Container>
  )
})

export default WebGLApplication;
