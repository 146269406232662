import frag from 'raw-loader!./shader/plane.frag.glsl'
import vert from 'raw-loader!./shader/plane.vert.glsl'
import { Mesh, RawShaderMaterial, ShaderMaterial, TextureLoader } from 'three';
import planeGeometry from './geometry/plane';
import { getCurrentMinWidth } from 'src/util/respondTo';
import { Breakpoint } from '../../data/styles/Breakpoint';

export default class Image3D {
  public mesh: Mesh;
  private material: RawShaderMaterial;
  private imageURL: string;
  private image: HTMLImageElement;
  private texture: TextureLoader;
  private viewport: any;
  private loaded: boolean = false;
  private retina: boolean = false;
  private resolution: number = 1920;
  private mobileRes: boolean = false;

  constructor(imageURL: string, viewport: any, retina: boolean = false, mobileRes = false) {
    this.imageURL = imageURL;
    this.viewport = viewport;
    this.retina = retina;
    this.mobileRes = mobileRes;
    this.resolution = mobileRes ? 768 : 1920;

    const geometry = planeGeometry(1,1);
    this.material = new ShaderMaterial({
      uniforms: {
        alpha: { value: 0 },
        map: { value: null },
        speed: { value: 1 },
        hoverTransition: { value: 0 },
        uTime: { value: 0 }
      },
      transparent: true,
      vertexShader: vert,
      fragmentShader: frag,
    })
    this.mesh = new Mesh(geometry, this.material);
  }

  preload(cb: any = undefined) {
    if (this.loaded) {
      this.resize(this.viewport);
      cb();
      return;
    }
    this.texture = new TextureLoader();
    this.texture.load(this.imageURL, (texture) => {

      this.image = texture.image;
      this.loaded = true;
      this.material.uniforms.map.value = texture;
      this.material.uniforms.map.value.needsUpdate = true;
      this.material.needsUpdate = true
      this.resize(this.viewport);
      if (cb) {
        cb();
      }
    });
  }

  public update() {
    this.material.needsUpdate = true
  }

  resize(viewport) {
    this.viewport = viewport;
    this.resolution = 1920;
    if (this.mobileRes) {
      this.resolution = getCurrentMinWidth() < Breakpoint.L ? 768 : 1920;
    }
    let modifier = 1;
    if (this.retina) {
      modifier = 0.5;
    }
    if (this.loaded) {
      const ratio = this.image.height / this.image.width;
      const width = (((this.image.width * 0.66) * modifier) / this.resolution);
      const height = (width * this.viewport.width) * (ratio)
      this.mesh.scale.x = width * this.viewport.width;
      this.mesh.scale.y = height
    }
  }

  set alpha(value) {
    this.material.uniforms.alpha.value = value;
    this.material.needsUpdate = true
  }

  set x(value) {
    this.mesh.position.x = value;
  }

  set y(value) {
    this.mesh.position.y = value;
  }

  set z(value) {
    this.mesh.position.z = value;
  }

  get x() {
    return this.mesh.position.x;
  }

  get y() {
    return this.mesh.position.y;
  }

  get z() {
    return this.mesh.position.z;
  }

  get width() {
    return this.mesh.scale.x
  }

  get alpha() {
    return this.material.uniforms.alpha.value
  }

  get height() {
    return this.mesh.scale.y
  }

  set hoverTransition(value) {
    this.material.uniforms.hoverTransition.value = value;
    this.material.needsUpdate = true
  }

  get hoverTransition() {
    return this.material.uniforms.hoverTransition.value;
  }

  set uTime(value) {
    this.material.uniforms.uTime.value = value;
    this.material.needsUpdate = true
  }

  set hoverSpeed(value) {
    this.material.uniforms.speed.value = value;
    this.material.needsUpdate = true
  }

  get uTime() {
    return this.material.uniforms.uTime.value;
  }
}
