import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  width: 1.6rem;
  height: 1.2rem;
  svg {
    width: 100%;
  }
`;
