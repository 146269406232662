import MobileMenu from 'src/components/molecules/MobileMenu';
import '../common/typography/Fonts/fonts.css';
import React, { useEffect, useState } from "react"
import '../../util/i18n';
import { GlobalStyle, HeaderContainer } from './styled';
import { ThemeProvider } from 'styled-components';
import theme from '../../data/styles/themes/default'
import WebGLApplication from '../organisms/WebGLApplication';

export const ThemeContext = React.createContext(theme);

export const AppContext = React.createContext({
  ...theme,
  setMenuOpen: (value) =>{},
  isMenuOpen: false,
  setBackground: (value) =>{},
  background: 'white',
})


export default function Layout({ children, location }: any) {

  const [background, setBackground] = useState('black');
  const [loaded, setLoaded] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const appTheme = {
    ...theme,
    setBackground,
    background,
    setMenuOpen,
    isMenuOpen,
  }
  useEffect(() => {
    setLoaded(true);
  }, [])

  return (
    <React.Fragment>
      <AppContext.Provider value={appTheme}>
        <ThemeProvider theme={theme}>
          <WebGLApplication />
          <GlobalStyle />
          {loaded &&
            <HeaderContainer location={location} />
          }
          {(loaded && isMenuOpen) &&
            <MobileMenu />
          }
          {loaded &&
            children
          }
        </ThemeProvider>
      </AppContext.Provider>
    </React.Fragment>
  )
}
