import React, { forwardRef, useRef, useImperativeHandle } from "react"
import { Container } from './styled';
import Toggle from 'src/assets/icons/toggle-view.svg';

export interface ToggleIndexViewProps { className?: string };

const ToggleIndexView = forwardRef((props: ToggleIndexViewProps, ref) => {

  return (
    <Container className={props.className}>
      <Toggle />
    </Container>
  )
})

export default ToggleIndexView;
