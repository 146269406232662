import styled from 'styled-components';
import { TypeHeading } from '../../common/typography/TypeHeading';
import { TypeParagraph } from '../../common/typography/TypeParagraph';

export const Container = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${props => props.background === 'white' ? 'white' : 'black'};
  z-index: 1;
`;

export const MenuItems = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const NavItem = styled(TypeHeading)`
  font-family: ${props => props.theme.font.helveticaNeueLight};
  text-transform: uppercase;
  background: none;
  border: none;
  color: ${props => props.background === 'white' ? 'black' : 'white'};
  cursor: pointer;
  display: block;
  margin-bottom: 1rem;
`;


export const Footer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 5.4rem;
`

export const ContactWrapper = styled.div`
  text-align: center;
  margin-bottom: 1.2rem;
`;

export const ContactCopy = styled(TypeParagraph)`
  font-family: ${props => props.theme.font.helveticaNeueLight};
  text-transform: uppercase;
  color: ${props => props.background === 'white' ? 'black' : 'white'};;
`;

export const AddressCopy = styled(TypeParagraph)`
  margin-bottom: 12px;
  font-family: ${props => props.theme.font.cardinalRegular};
  text-transform: uppercase;
  display: block;
  color: ${props => props.background === 'white' ? 'black' : 'white'};;
  text-align: center;
  width: 100%;
`;

export const SocialWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 70%;
  margin: 0 auto;
`

export const SocialLink = styled(TypeParagraph)`
  pointer-events: auto;
  font-family: ${props => props.theme.font.helveticaNeueLight};
  text-transform: uppercase;
  &:hover {
    text-decoration: underline;
  }
  color: ${props => props.background === 'white' ? 'black' : 'white'};;
`

