import styled from 'styled-components';
import { Breakpoint } from '../../../data/styles/Breakpoint';
import { maxWidth, minWidth } from '../../../util/style/mixins/respondTo';
import { TypeParagraph } from '../../common/typography/TypeParagraph';
import Filter from '../../molecules/Filter';
import Menu from '../../molecules/Menu';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: ${props => props.theme.layers.header};
  padding-left: ${props => props.theme.spacing.SPACING_2}rem;
  padding-top: 4rem;
  padding-right: ${props => props.theme.spacing.SPACING_2}rem;
  mix-blend-mode: difference;
  color: white;

  ${minWidth(Breakpoint.M)} {
    padding-left: ${props => props.theme.spacing.SPACING_1}rem;
    padding-top: ${props => props.theme.spacing.SPACING_1}rem;
    padding-right: ${props => props.theme.spacing.SPACING_1}rem;
  }
`;

export const NavItem = styled(TypeParagraph)`
  font-family: ${props => props.theme.font.helveticaNeueLight};
  text-transform: uppercase;
  background: none;
  border: none;
  color: white;
  position: relative;
  z-index: 2;
  opacity: ${props => props.active ? 1 : 0.4};
  &:hover {
    opacity: 1;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
`
export const SortBy = styled(TypeParagraph)`
  font-family: ${props => props.theme.font.cardinalItalic};
  margin-right: 1rem;

  ${minWidth(Breakpoint.M)} {
    margin-right: 4.7rem;
  }
`

export const FilterBy = styled(Filter)``;

export const MenuContainer = styled(Menu)`
`;
