import { navigate } from "gatsby"
import gsap from 'gsap';
import happens from 'happens';
import { Object3D, PerspectiveCamera, Scene, WebGLRenderer } from 'three';
import { Breakpoint } from '../../data/styles/Breakpoint';
import IIndexProject from '../../interface/IIndexProject';
import IndexPlane from './IndexPlane';
import scroller from 'src/util/scroller';
import Image3D from './Image3D';
import WebGLFooter from './WebGLFooter';

export default class WebGLIndexPage {
  private app: any;
  private scene: Scene;
  private camera: PerspectiveCamera;
  private renderer: WebGLRenderer;
  private projectList: Array<any> = []
  private logo: Image3D;
  private footer: WebGLFooter;
  private mesh: Object3D;
  private automaticAnimation:any;
  private layout: {
    horizontalMargin: number
    height: number
  } = {
    horizontalMargin: 7,
    height: 0
  }
  public on: Function;
  public emit: Function;
  private active: boolean = false;
  private scrollInterval: any;
  private autoScrolling: any = false;

  constructor(app: any) {
    this.projectOver = this.projectOver.bind(this)
    this.projectOut = this.projectOut.bind(this)
    this.projectClicked = this.projectClicked.bind(this)
    this.startAutoScroll = this.startAutoScroll.bind(this)
    this.stopAutoScroll = this.stopAutoScroll.bind(this)
    this.onWheel = this.onWheel.bind(this)
    this.onScrollToContactStart = this.onScrollToContactStart.bind(this)
    this.onScrollToContactEnd = this.onScrollToContactEnd.bind(this)
    this.app = app;
    this.mesh = new Object3D();
    this.scene = this.app.scene;
    this.camera = this.app.camera;
    this.renderer = this.app.renderer;
    happens(this);
  }

  public initialize(data) {
    data.map((element: IIndexProject, i: number) => {
      const project = new IndexPlane(element, this.app, i);
      project.on('over', this.projectOver);
      project.on('out', this.projectOut);
      project.on('clicked', this.projectClicked);
      this.mesh.add(project.mesh);
      this.projectList.push(project);
    });
    this.logo = new Image3D('/logo.jpg', this.app.viewport, true, true);
    this.logo.mesh.visible = true;
    this.logo.mesh.position.z -= 8;
    this.scene.add(this.mesh);
    this.scene.add(this.logo.mesh);
    this.addEvents();
    this.addFooter()
  }

  private projectClicked(project) {
    navigate(`/case/${project.slug}`)

  }

  private projectOver(project) {
    this.emit('project:over', project)
    this.logo.alpha = 0;
  }

  private projectOut() {
    this.emit('project:out')
    this.logo.alpha = 1;
  }

  private preloadLogo() {
    this.logo.preload(() => {
      gsap.to(this.logo, { alpha: 1, duration: 2, ease: 'power4.inOut' })
    });
  }

  private addFooter() {
    this.footer = new WebGLFooter(this.app);
    this.footer.mesh.position.y = -this.app.viewport.height * 2;
    this.mesh.add(this.footer.mesh);
  }

  public update() {
    if (!this.active) return;
    this.projectList.map(project => project.update());
    if (this.footer) {
      this.footer.update();
    }
  }

  private onScrollToContactStart() {
    gsap.killTweensOf(this.automaticAnimation);
    this.autoScrolling = false;
  }

  private onScrollToContactEnd() {
    this.autoScrolling = true;
  }

  private async addEvents() {
    await scroller.ready();
    scroller.scroll.stop();
    scroller.on('contact:start', this.onScrollToContactStart)
    scroller.on('contact:end', this.onScrollToContactEnd)
    scroller.scroll.on('scroll', (scroll) => {
      if (this.autoScrolling) return;
      this.changeOnScroll(scroll);;
    })
  }

  private changeOnScroll(scroll) {
    const percent = scroll.scroll.y / scroll.limit.y;
    if (this.mesh.position.y < Math.abs(this.footer.mesh.position.y + this.app.viewport.height / 2)) {
      this.logo.mesh.visible = true;
    } else {
      this.logo.mesh.visible = false;
    }
    this.mesh.position.y = -percent * this.layout.height
    this.projectList.map((project) => {
      project.onScroll(scroll);
    })
  }

  private positionItems(): void {
    let col: number = 0;
    let row: number = 0;
    let margin;
    this.projectList.map((project: IndexPlane, i: number) => {
      const prev = i > 0 ? this.projectList[i - 1] : false;
      if (col === 0) {
        margin = this.layout.horizontalMargin;
      } else {
        margin = -this.layout.horizontalMargin;
      }
      project.x = this.app.viewport.left + (col * this.app.viewport.width) + margin;
      if (prev) {
        project.y = prev.y - (prev.height + 1.5);
      } else {
        project.y = this.app.viewport.top - this.app.viewport.height * 0.5;
      }
      project.resize(this.app.viewport);
      this.layout.height = project.y - project.height + (this.app.viewport.height);
      row += 1;
      col += 1;
      if (col === 2) {
        col = 0;
      }
    })
    if (this.footer) {
      this.footer.mesh.position.y = this.layout.height - (this.app.viewport.top * 2);
      this.footer.mesh.position.y -= this.app.viewport.height * 0.9;
      this.layout.height -= this.app.viewport.height * 2;
    }
  }

  public resize(): void {
    this.layout.horizontalMargin = .114 * this.app.viewport.width;
    this.positionItems()
    if (this.footer) {
      this.footer.resize();
    }
  }

  public onBreakpointChange(breakpoint: number): void {
    this.resize();
  }

  private preload(cb) {
    let preloaded = 0;
    const checkPreloaded = () => {
      preloaded++;
      if (preloaded >= this.projectList.length - 1) {
        preloadTimeline.play();
      }
    }

    const preloadTimeline = gsap.timeline({ paused: true, onComplete: () => {
      cb();
    } });

    this.projectList.map((p, i) => {
      preloadTimeline.add(p.preload(checkPreloaded), i / 8)
    })
  }

  public transitionIn(): void {
    this.active = true;
    this.mesh.visible = true;
    this.preloadLogo()
    this.preload(() => {
      this.positionItems()
      scroller.scroll.start();
      window.addEventListener('wheel', this.onWheel);
      window.addEventListener('touchstart', this.stopAutoScroll);
      this.startAutoScroll()
      const timeline = gsap.timeline({ paused: true})
      this.projectList.map((p, i) => {
        timeline.add(p.transitionIn(), i * 0.025)
      })
      timeline.restart();
    });
  }

  private onWheel() {
    if (this.autoScrolling ) {
      scroller.scroll.scrollTo(this.automaticAnimation.value, { duration: 0, disableLerp: true });
      gsap.killTweensOf(this.automaticAnimation);
      this.autoScrolling = false;
    }
    window.clearTimeout(this.scrollInterval);
    this.scrollInterval = setTimeout(this.startAutoScroll, 500);
  }

  private startAutoScroll() {
    this.autoScrolling = true;
    this.automaticAnimation = {value: scroller.scroll.scroll.instance.scroll.y };
    gsap.to(this.automaticAnimation, {
      value: scroller.scroll.scroll.instance.limit.y,
      duration: 240 * (10 / this.projectList.length) *  (1 - this.automaticAnimation.value / scroller.scroll.scroll.instance.limit.y),
      onUpdate: () => {
        this.changeOnScroll({
          scroll: {
            y: this.automaticAnimation.value,
          },
          limit: {
            y: scroller.scroll.scroll.instance.limit.y
          }
        });
      }
    })
  }

  private stopAutoScroll() {
    gsap.killTweensOf(this.automaticAnimation);
    scroller.scroll.scrollTo(this.automaticAnimation.value, { duration: 0, disableLerp: true });
    window.removeEventListener('wheel', this.onWheel);
    window.removeEventListener('touchstart', this.stopAutoScroll);
    this.autoScrolling = false;
  }

  public transitionOut(): void {
    window.clearTimeout(this.scrollInterval);
    this.stopAutoScroll();
    scroller.off('contact:start', this.onScrollToContactStart)
    scroller.off('contact:end', this.onScrollToContactEnd)
    this.active = false;
    this.mesh.visible = false;
    this.projectList.map((p, i) => {
      p.dispose();
    });
  }
}
