import gsap from 'gsap';
import { Object3D } from 'three';
import random from '../../util/random';
import Image3D from './Image3D';
import scroller from 'src/util/scroller';
import IAsset from '../../interface/IAsset';
import { getCurrentMinWidth } from '../../util/respondTo';
import { Breakpoint } from '../../data/styles/Breakpoint';
import { throws } from 'assert';

function randomIntFromInterval(min, max) { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export default class IndexThumbnail {
  public mesh: Object3D;
  private imageData: IAsset;
  private image: Image3D;
  private viewport: any;
  public x: number = 0;
  public y: number = 0;
  private speed: number = 0;
  private angle: number = 0;
  private cover: any;
  private scale: any;
  private isHovering: boolean = false;
  private overZIndex: number;
  private radius: number;
  private resolution: number;
  private xOffRand: number;
  private index: number;
  private scrollSpeed: number;
  private transitioned: boolean = false;
  private scrollValue;

  constructor(imageData: IAsset, app: any, angle: number, cover: any, index: any, z: number) {
    this.imageData = imageData;
    this.index = index;
    this.resolution = getCurrentMinWidth() < Breakpoint.L ? 768 : 1920;
    this.radius = 2 * (window.innerWidth / this.resolution);
    this.cover = cover;
    this.viewport = app.viewport;
    this.speed = 1 + Math.random() * 0.5;
    this.angle = angle;
    this.scale = Math.max(0.35,(Math.random() * 0.5));
    this.isHovering = false;
    this.onScroll = this.onScroll.bind(this);
    this.overZIndex = z;
    this.xOffRand = Math.max(1,Math.abs(z) * 10);
    this.scrollSpeed = Math.max(0.001, Math.random() * 0.003);

    this.mesh = new Object3D();
    this.image = new Image3D(app.theme.urls.assetsBase() + this.imageData.formats.medium.url, this.viewport, false, true);
    this.image.hoverSpeed = Math.max(0.5, Math.random());
    this.mesh.scale.set(0.25 + this.scale, 0.25 + this.scale, 0.25 + this.scale)
    this.mesh.position.z = this.overZIndex;
    this.mesh.add(this.image.mesh);
  }

  private onScroll(scroll?: any) {
    if (scroll) {
      this.scrollValue = scroll.scroll.y;
    } else {
      this.scrollValue = scroller.scroll.scroll.instance.scroll.y
    }
    if (!this.isHovering && this.transitioned) {
      this.animate(0.7);
    }
  }

  async preload() {
    this.mesh.visible = true;
    this.image.preload(() => {
      this.transitioned = true;
      this.onScroll();
    });
  }

  public dispose() {
    this.transitioned = false;
    this.image.x = 0;
    this.image.y = 0;
    this.mesh.visible = false;
  }

  public overAnimation(x) {
    this.isHovering = true;
    gsap.killTweensOf(this.image);
    gsap.to(this.image, {
      duration: 0.7,
      hoverTransition: 1,
      ease: 'expo.out',
    })
  }

  public outAnimation(delay) {
    gsap.killTweensOf(this.image);
    gsap.to(this.image, { duration: 0.5, hoverTransition: 0, ease: 'expo.out' } )
  }

  public animate(duration = 0.7, delay = 0, ease = 'power4.out', onComplete = () =>{}) {
    const tl = gsap.timeline();
    const xOffset = this.index % 2 === 0 ? this.xOffRand: - this.xOffRand;
    const randX = xOffset * 0.5;
    const randY = Math.sin(this.angle * 3) * (this.radius * 0.8);
    this.x = randX;
    // this.y = randY - this.cover.height + (this.scrollY * this.scrollSpeed);
    this.y = randY - this.cover.height + ((this.scrollY / (this.index + 1)) * this.scrollSpeed);
    tl.to(this.image, {
      x: this.x,
      y: this.y,
      alpha: 1,
      ease,
      duration,
      delay,
      onComplete
    }, 0);
    return tl;
  }

  public update() {
    this.image.uTime += 0.1;
    this.image.update();
  }

  resize(viewport) {
    this.viewport = viewport;
    this.image.resize(viewport);
  }

  get width() {
    return this.image.width;
  }

  get height() {
    return this.image.height
  }

  get scrollY() {
    return (this.scrollValue - this.y) || 0;
  }
}
