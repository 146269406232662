import styled from 'styled-components';
import { TypeParagraph } from '../../common/typography/TypeParagraph';

export const Container = styled.div`
  display: block;
`;

export const Label = styled(TypeParagraph)`
  font-family: ${props => props.theme.font.helveticaNeueLight};
  text-transform: uppercase;
`
