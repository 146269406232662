import styled from 'styled-components';
import { Breakpoint } from '../../../data/styles/Breakpoint';
import { minWidth } from '../../../util/style/mixins/respondTo';
import ToggleIndexView from '../../atoms/ToggleIndexView';
import { TypeParagraph } from '../../common/typography/TypeParagraph';

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  ${minWidth(Breakpoint.M)} {
    a {
      margin-right: 3.5rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

export const ContactLink = styled.a`

`

export const NavItem = styled(TypeParagraph)`
  font-family: ${props => props.theme.font.helveticaNeueLight};
  text-transform: uppercase;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  position:relative;
  z-index: 2;
  opacity: ${props => props.active ? 1 : 0.4};
  &:hover {
    opacity: 1;
  }
`;

export const ToggleView = styled(ToggleIndexView)`
  display: none;

  ${minWidth(Breakpoint.M)} {
    display: block;
  }
`;
