import { Link } from 'gatsby';
import React, { forwardRef, useContext, useEffect } from "react"
import Clock from 'react-live-clock';
import { useTranslation } from 'react-i18next';
import { TypeParagraphSizes } from '../../common/typography/TypeParagraph';
import { AppContext } from '../../Layout/Layout';
import { Container, NavItem, MenuContainer } from './styled';

export interface HeaderProps { className?: string, location: any };

const Header = forwardRef((props: HeaderProps, ref) => {
  const { t, i18n } = useTranslation();

  let context = useContext(AppContext);

  return (
    <Container className={`${props.className}`} >
      <Link to="/">
        <NavItem active={location.pathname === '/'} background={context.background} size={TypeParagraphSizes.M}>{ t('index') }</NavItem>
      </Link>
      <MenuContainer location={location} />
    </Container>
  )
})

export default Header;
