import styled from 'styled-components';
import { TypeHeading } from '../../common/typography/TypeHeading';

export const Container = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const ProjectTitle = styled(TypeHeading)`
  color: ${props => props.theme.color.black};
  font-family: ${props => props.theme.font.helveticaNeueRegular};
  position: fixed;
  opacity: ${props => props.visible ? 1 : 0};
  top: 50%;
  left: 50%;
  width: 78%;
  text-align: center;
  transform: translateX(-50%) translateY(-50%);
  text-transform: uppercase;
  pointer-events: none;
`
