import { DoubleSide, Mesh, MeshBasicMaterial, Object3D, PlaneBufferGeometry, PlaneGeometry, TextureLoader } from 'three';
import cylinderGeometry from '../webgl/geometry/cylinder';

export default class WebGLFooter {
  private app: any;
  private viewport: any;
  private material: any;
  public mesh: any;
  public mesh2: any;
  public mesh1: any;
  public rotation1: any;
  public rotation2: any;
  public mouse: any;
  public bg: any;

  constructor(app, img = "/footer.png", background = false) {
    this.onMouseMove = this.onMouseMove.bind(this);
    this.app = app;
    this.viewport = this.app.viewport;
    this.mesh = new Object3D();
    this.mesh1 = new Object3D();
    this.rotation1 = new Object3D();
    const hratio = 0.5 / 1.3;
    const geometry = cylinderGeometry(1, 1 * hratio);
    const texture = new TextureLoader().load( img );
    this.material = new MeshBasicMaterial({
      map: texture,
      side: DoubleSide,
      transparent: true,
    })
    this.material.depthWrite = false;
    this.mouse = { x: 0, y: 0 };
    this.mesh1 = new Mesh(geometry, this.material);
    this.rotation1.add(this.mesh1);
    this.mesh.add(this.rotation1);
    this.mesh.position.z = -0.5;
    if (background) {
      this.addBackground();
    }
    this.resize();
    document.addEventListener('mousemove', this.onMouseMove, false);
  }

  addBackground() {
    const geo = new PlaneBufferGeometry(1,1);
    const material = new MeshBasicMaterial({ color: 0x000000 });
    this.bg = new Mesh(geo, material);
    this.bg.position.z = -3
    this.mesh.add(this.bg);
  }

  onMouseMove(event) {
    event.preventDefault();
    this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
  }

  update() {
    this.rotation1.rotation.z = this.mouse.x;
    this.mesh1.rotation.x = 0.1;
    this.mesh1.rotation.y += 0.03;
  }

  resize() {
    this.rotation1.scale.x = this.viewport.width * 0.27;
    this.rotation1.scale.y = this.viewport.width * 0.27;;
    if (this.bg) {
      this.bg.scale.x = this.viewport.width * Math.abs(this.bg.position.z);
      this.bg.scale.y = (this.viewport.height * 1) * Math.abs(this.bg.position.z);
    }
  }
}
