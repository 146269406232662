import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { forwardRef, useContext } from "react"
import { useTranslation } from 'react-i18next';
import { TypeHeadingSizes } from '../../common/typography/TypeHeading';
import { TypeParagraphSizes } from '../../common/typography/TypeParagraph';
import { AppContext } from '../../Layout/Layout';
import { Container, MenuItems, NavItem, Footer, ContactWrapper, AddressCopy, ContactCopy,SocialLink, SocialWrapper } from './styled';

export interface MobileMenuProps { className?: string };

const MobileMenu = forwardRef((props: MobileMenuProps, ref) => {

  const { t } = useTranslation();
  let context = useContext(AppContext);

  const data = useStaticQuery(graphql`
    query Footer {
      allStrapiFooter {
        edges {
          node {
            Footer {
              Address
              Copyright
              Email
              SocialMedia {
                Name
                URL
              }
              Telephone
            }
          }
        }
      }
    }
  `)

  const footerData = data.allStrapiFooter.edges[0].node.Footer;

  const telephone = footerData.Telephone;
  const email = footerData.Email;
  const address = footerData.Address;
  const social = footerData.SocialMedia;

  const renderSocial = social.map((s, i) => {
    return <SocialLink background={context.background} target="_blank" key={i} size={TypeParagraphSizes.S} as={'a'} href={s.URL}>{s.Name}</SocialLink>
  })

  return (
    <Container background={context.background} className={props.className}>
      <MenuItems>
        <Link to='/about'>
            <NavItem background={context.background} size={TypeHeadingSizes.H1}>{ t('studio') }</NavItem>
          </Link>
          <Link to='/archive'>
            <NavItem background={context.background} size={TypeHeadingSizes.H1}>{ t('archive') }</NavItem>
          </Link>
      </MenuItems>
      <Footer>
        <ContactWrapper>
          <ContactCopy background={context.background} size={TypeParagraphSizes.S}>{telephone}</ContactCopy>
          <ContactCopy background={context.background} size={TypeParagraphSizes.S}>{email}</ContactCopy>
        </ContactWrapper>
        {(address && address.length) > 0 &&
          <AddressCopy background={context.background} size={TypeParagraphSizes.S}>{address}</AddressCopy>
        }
        <SocialWrapper>
          {renderSocial}
        </SocialWrapper>
      </Footer>
    </Container>
  )
})

export default MobileMenu;
